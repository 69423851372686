<script>
import Layout from '../../../layouts/main';
import appConfig from '@/app.config';
import Pagination from '@/components/Pagination/main.vue';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import DatePicker from 'vue2-datepicker';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import { keyMaster, listPaging, masterMethods, checkPermission, prizeMethods, employeeMethods } from '@/state/helpers';
import { truncateText } from '../../../../utils/format';
import { showMessage } from '@/utils/messages';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '賞品戻り管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Multiselect,
        Pagination,
        Layout,
        DatePicker,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            campaign_pic: {},
            listData: [],
            listDataMasterTeam: [],
            listDataMasterGroup: [],
            listDataMasterCampaignPic: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_closing_date: '',
                secretariat_closing_date_y: '',
                secretariat_closing_date_m: '',
                secretariat_closing_date_d: '',
                prize: ''
            },
            has: {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            },
            perPageList: listPaging,
            customFormatDate: 'YYYY-MM-DD',
            checkShowFilter: false,
            listTagFreeWord: [],
            listDataMasterCompare: [],
            listTagKeyWord: [],
            configKeyword: {
                arrayTag: [],
                placeholder: '賞品名、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            },
            isLoading: false
        };
    },
    watch: {
        'form.secretariat_closing_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.form.secretariat_closing_date_y = date[0];
                this.form.secretariat_closing_date_m = date[1];
                this.form.secretariat_closing_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'form.return_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.form.return_date_y = date[0];
                this.form.return_date_m = date[1];
                this.form.return_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        campaign_pic: {
            deep: true,
            handler: function (value) {
                if (value) this.has.campaign_pic = value.id;
                else this.has.campaign_pic = '';
            }
        },
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        }
    },
    mounted() {
        this.getListMasterCompare();
        this.getListMasterCampaignPic();
        this.getList();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...prizeMethods,
        ...employeeMethods,
        truncateTextTable(text) {
            return truncateText(text);
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getListMasterCompare() {
            this.listMaster(keyMaster['prize.prize_less_status']).then((data) => {
                this.listDataMasterCompare = data;
            });
        },
        getListMasterCampaignPic() {
            this.listMaster('users?filters[role]=3' + '&limit=999').then((data) => {
                this.listDataMasterCampaignPic = data;
            });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }

                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listPrizeReturn(query).then((data) => {
                data.data.forEach((item) => {
                    if (!item.return_status) item.return_status = { id: '' };
                    if (!item.prize)
                        item.prize = {
                            delivery_company: ''
                        };
                });
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        deletePrizeRe(data) {
            Swal.fire({
                title: '賞品戻りを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    this.deletePrizeReturn(data).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品戻りが削除されました。');
                            this.getList();
                        }
                    });
                }
            });
        },
        search() {
            let data = {
                form: this.form,
                filter: this.filter
            };
            localStorage.setItem('employee', JSON.stringify(data));
            this.page = 1;
            this.getList();
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名、賞品名、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo',
                isShowLable: true
            };
            this.form = {
                campaign_name: '',
                reason: '',
                return_date: '',
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                storage_code: '',
                contact_information_number: '',
                shipping_company: '',
                province: '',
                secretariat_closing_date: '',
                secretariat_closing_date_y: '',
                secretariat_closing_date_m: '',
                secretariat_closing_date_d: '',
                prize: ''
            };
            this.has = {
                campaign_pic: '',
                prize: '',
                return_status: '',
                campaign: ''
            };
            this.campaign_pic = {};
            this.getList();
        },
        saveItem(item) {
            if (!checkPermission('return_prize.edit')) return;
            if (!item.return_date || !item.reason || !item.reconfirmation_schedule) {
                return;
            }
            this.updatePrizeReturn(item).then((data) => {
                if (data.code == 422) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = '賞品名、戻り理由、戻り日、運送会社、都道府県、お問い合わせNo';
                this.configKeyword.arrayTag = this.listTagFreeWord;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名<span class="text-danger">*</span></label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.campaign_name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">賞品名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.prize" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り理由</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.reason" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">案件担当</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    v-model="campaign_pic"
                                                    track-by="id"
                                                    label="name"
                                                    placeholder=""
                                                    :options="listDataMasterCampaignPic"
                                                    :searchable="true"
                                                >
                                                </multiselect>
                                                <i
                                                    v-if="campaign_pic && campaign_pic.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        campaign_pic = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 23px; z-index: 9; top: 11px; background: #fff"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">戻り日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="form.return_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="form.return_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="form.return_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="form.return_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">保管棚番</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.storage_code" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">運送会社</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.shipping_company" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">都道府県</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.province" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">事務局終了日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        v-model="form.secretariat_closing_date_y"
                                                        style="width: 80px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    年
                                                    <input
                                                        type="text"
                                                        v-model="form.secretariat_closing_date_m"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    月
                                                    <input
                                                        type="text"
                                                        v-model="form.secretariat_closing_date_d"
                                                        style="width: 47px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    日
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        カレンダー
                                                        <date-picker
                                                            v-model="form.secretariat_closing_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">問い合わせNo</label>
                                            <div class="col-sm-9">
                                                <input
                                                    id="validationCustom05"
                                                    v-model="form.contact_information_number"
                                                    type="text"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>

                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">賞品戻り検索結果</h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>終了FLG</th>
                                        <th>No.</th>
                                        <th>戻り日 <span class="text-danger">*</span></th>
                                        <th>キャンペーン名</th>
                                        <th>都道府県</th>
                                        <th>運送会社</th>
                                        <th>問合せ№</th>
                                        <th>戻り理由 <span class="text-danger">*</span></th>
                                        <th>対応指示 <span class="text-danger">*</span></th>
                                        <th>再確認日程 <span class="text-danger">*</span></th>
                                        <th>パルディアより対応指示項目</th>
                                        <th>日本物流開発様対応</th>
                                        <th>保管棚番</th>
                                        <th>備考</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template v-for="(item, k) in listData">
                                        <tr :key="'ac' + k">
                                            <td>
                                                <div class="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="item.end_flag"
                                                        class="custom-control-input"
                                                        value="true"
                                                        :id="`__BVID__742` + k"
                                                        @change="saveItem(item)"
                                                    />
                                                    <label class="custom-control-label" :for="`__BVID__742` + k"><label></label></label>
                                                </div>
                                            </td>

                                            <td>
                                                {{ k + 1 }}
                                            </td>

                                            <td style="width: 123px">
                                                <div>
                                                    <date-picker
                                                        @change="saveItem(item)"
                                                        style="min-width: 130px"
                                                        ref="return_date"
                                                        v-model="item.return_date"
                                                        :value-type="customFormatDate"
                                                        :first-day-of-week="1"
                                                        lang="ja"
                                                        :input-class="`form-control-sm form-control ${!item.return_date ? 'is-invalid' : ''}`"
                                                    ></date-picker>
                                                </div>
                                                <div v-if="!item.return_date" class="mt-1">
                                                    <span class="text-danger">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: '戻り日'
                                                        })
                                                    }}</span>
                                                </div>
                                            </td>

                                            <td>
                                                {{ item?.prize?.event_prizes?.campaign?.name ?? '' }}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.province"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    disabled
                                                    v-model="item.prize.delivery_company"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    style="width: 140px"
                                                    v-model="item.contact_information_number"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td class="input-group-sm">
                                                <div>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        style="min-width: 140px"
                                                        v-model="item.reason"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{ 'is-invalid': !item.reason }"
                                                    />
                                                </div>
                                                <div v-if="!item.reason" class="mt-1">
                                                    <span class="text-danger">{{
                                                        $t('validateField.required', {
                                                            field: '戻り理由'
                                                        })
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="input-group-sm">
                                                <select
                                                    class="form-control"
                                                    @change="saveItem(item)"
                                                    v-model="item.return_status.id"
                                                    style="width: 190px"
                                                >
                                                    <option :value="i.id" v-for="i in listDataMasterCompare" :key="i.id">{{ i.value }}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <date-picker
                                                        @change="saveItem(item)"
                                                        style="min-width: 130px"
                                                        ref="scheduled_arrived_at"
                                                        v-model="item.reconfirmation_schedule"
                                                        :value-type="customFormatDate"
                                                        :first-day-of-week="1"
                                                        lang="ja"
                                                        :input-class="`form-control-sm form-control ${!item.reconfirmation_schedule ? 'is-invalid' : ''}`"
                                                    ></date-picker>
                                                </div>
                                                <div v-if="!item.reconfirmation_schedule" class="mt-1">
                                                    <span class="text-danger">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: '再確認日程'
                                                        })
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.instruction"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.compatiable_with_jp_logistic_development"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.storage_code"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    @change="saveItem(item)"
                                                    v-model="item.note"
                                                    style="width: 140px"
                                                    placeholder=""
                                                    class="form-control-sm form-control"
                                                />
                                            </td>
                                            <td align="center">
                                                <button
                                                    v-if="checkPermission('return_prize.delete')"
                                                    class="btn btn-sm btn-outline-danger"
                                                    type="button"
                                                    @click="deletePrizeRe(item)"
                                                    style="border-radius: 50%"
                                                >
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss">
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
}
.mx-input {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-width: 123px;
}
</style>
